/**
 * @fileoverview gRPC-Web generated client stub for legal
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.12
// source: legal/personReadService.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js')

var security_context_pb = require('../security/context_pb.js')

var legal_person_pb = require('../legal/person_pb.js')
const proto = {};
proto.legal = require('./personReadService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.legal.PersonReadServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.legal.PersonReadServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.legal.ListPersonsRequest,
 *   !proto.legal.ListPersonsResponse>}
 */
const methodDescriptor_PersonReadService_ListPersons = new grpc.web.MethodDescriptor(
  '/legal.PersonReadService/ListPersons',
  grpc.web.MethodType.UNARY,
  proto.legal.ListPersonsRequest,
  proto.legal.ListPersonsResponse,
  /**
   * @param {!proto.legal.ListPersonsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.legal.ListPersonsResponse.deserializeBinary
);


/**
 * @param {!proto.legal.ListPersonsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.legal.ListPersonsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.legal.ListPersonsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.legal.PersonReadServiceClient.prototype.listPersons =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/legal.PersonReadService/ListPersons',
      request,
      metadata || {},
      methodDescriptor_PersonReadService_ListPersons,
      callback);
};


/**
 * @param {!proto.legal.ListPersonsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.legal.ListPersonsResponse>}
 *     Promise that resolves to the response
 */
proto.legal.PersonReadServicePromiseClient.prototype.listPersons =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/legal.PersonReadService/ListPersons',
      request,
      metadata || {},
      methodDescriptor_PersonReadService_ListPersons);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.legal.GetPersonRequest,
 *   !proto.legal.Person>}
 */
const methodDescriptor_PersonReadService_GetPerson = new grpc.web.MethodDescriptor(
  '/legal.PersonReadService/GetPerson',
  grpc.web.MethodType.UNARY,
  proto.legal.GetPersonRequest,
  legal_person_pb.Person,
  /**
   * @param {!proto.legal.GetPersonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  legal_person_pb.Person.deserializeBinary
);


/**
 * @param {!proto.legal.GetPersonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.legal.Person)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.legal.Person>|undefined}
 *     The XHR Node Readable Stream
 */
proto.legal.PersonReadServiceClient.prototype.getPerson =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/legal.PersonReadService/GetPerson',
      request,
      metadata || {},
      methodDescriptor_PersonReadService_GetPerson,
      callback);
};


/**
 * @param {!proto.legal.GetPersonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.legal.Person>}
 *     Promise that resolves to the response
 */
proto.legal.PersonReadServicePromiseClient.prototype.getPerson =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/legal.PersonReadService/GetPerson',
      request,
      metadata || {},
      methodDescriptor_PersonReadService_GetPerson);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.legal.UnmaskPersonRequest,
 *   !proto.legal.Person>}
 */
const methodDescriptor_PersonReadService_UnmaskPerson = new grpc.web.MethodDescriptor(
  '/legal.PersonReadService/UnmaskPerson',
  grpc.web.MethodType.UNARY,
  proto.legal.UnmaskPersonRequest,
  legal_person_pb.Person,
  /**
   * @param {!proto.legal.UnmaskPersonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  legal_person_pb.Person.deserializeBinary
);


/**
 * @param {!proto.legal.UnmaskPersonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.legal.Person)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.legal.Person>|undefined}
 *     The XHR Node Readable Stream
 */
proto.legal.PersonReadServiceClient.prototype.unmaskPerson =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/legal.PersonReadService/UnmaskPerson',
      request,
      metadata || {},
      methodDescriptor_PersonReadService_UnmaskPerson,
      callback);
};


/**
 * @param {!proto.legal.UnmaskPersonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.legal.Person>}
 *     Promise that resolves to the response
 */
proto.legal.PersonReadServicePromiseClient.prototype.unmaskPerson =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/legal.PersonReadService/UnmaskPerson',
      request,
      metadata || {},
      methodDescriptor_PersonReadService_UnmaskPerson);
};


module.exports = proto.legal;

